import React, { useState } from "react";
import PreTranslation from "components/PreTranslation/PreTranslation";
import SEO from "components/seo";
import ToolFaq from "components/QualityTools/ToolFaq";
import { PreTranslationToolFaqData } from "components/FAQ/config";
import Layout from "components/layout";
import ResultPage from "components/PostTranslation/Result";

const Result = () => {
  return (
    <Layout>
      <SEO
        title="AI-Powered Translation Quality Assessment Tool"
        description="The Tomedes AI-Powered Translation QA Tool checks translation accuracy by comparing it to the source text. Get a detailed report and translation quality scores for fluency, grammar, terminology, and style."
        slug="/tools/pre-translation"
      />
      <ResultPage />
    </Layout>
  );
};

export default Result;
