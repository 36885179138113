import React, { useEffect, useRef, useState } from "react";
import "swiper/css";
import "swiper/css/grid";
import Nexticon from "assets/nexticon.svg";
import Pre from "assets/pre.svg";
import { Tooltip } from "react-tooltip";
import DownloadIcon from "assets/downloadtool.jpg";
import CopyIcon from "assets/copytools.jpg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import * as XLSX from "xlsx";
import DownloadSection from "utils/resultDownload";


const TermsOutput = ({ keyTermOutput, headerText,untranslate,headerDescription }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [copySuccess, setCopySuccess] = useState("Copy");
  const [selectedFormat, setSelectedFormat] = useState("Download CSV");
  const downloadOutputFormat = ["Download CSV", "Download XLS"];
  const sliderRef = useRef(null);
  const refDiv = useRef(null);
  const handleSlideChange = (swiper) => {
    const currentIndex = swiper.activeIndex + 1;
    setCurrentPage(currentIndex);
  };

  const copyText = () => {
    if (refDiv.current) {
      const textToCopy = refDiv.current.innerText;
      navigator.clipboard.writeText(textToCopy);
      setCopySuccess("Copied");
      setTimeout(() => {
        setCopySuccess("Copy");
      }, 1500);
    }
  };
  const handleFormatChange = (format) => {
    setSelectedFormat(format);
  };

  const handleDownload = (format) => {
    if (format === "Download CSV") {
      downloadCSV();
    } else if (format === "Download XLS") {
      downloadXLS();
    }
  };
  const keyTermResult =
    keyTermOutput?.result?.Untranslated_Terms ||
    keyTermOutput?.result?.Missing_Terms;
   
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      rows: 5,
      beforeChange: (current, next) => {
        setCurrentPage(next + 1);
      },
      focusOnSelect: true,
    };

    const handleNextSlide = () => {
      sliderRef.current.slickPrev();
    };
  
    const handlePrevSlide = () => {
      sliderRef.current.slickNext();
    };
      useEffect(() => {
        setTotalPages(Math.ceil(keyTermResult.length / 5));
      }, [keyTermResult]);

      
      const downloadCSV = () => {
        const headers = !untranslate 
          ? ["Source Text", "Translation", "Missing Translation"]
          : ["Source", "Translation", "Untranslated Text"];
      

        const data = keyTermResult.map((item) => ({
          source: item?.Source, 
          translation: item?.Translation, 
          untranslated: item?.Untranslated_Text || item?.Missing_Term,
        }));
      

        const escapeCSVText = (text) => {
          if (text) {
     
            return `"${text.replace(/"/g, '""')}"`;
          }
          return '""'; 
        };
      

        const csvRows = [];
        csvRows.push(headers.join(","));

        data.forEach((item) => {
          const row = [
            escapeCSVText(item.source),  
            escapeCSVText(item.translation), 
            escapeCSVText(item.untranslated),  
          ];
          csvRows.push(row.join(","));
        });

        const csvString = csvRows.join("\n");

        const blob = new Blob([csvString], { type: "text/csv" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "translations.csv";  
        a.click();
        URL.revokeObjectURL(url); 
      };
      const downloadXLS = () => {
        const headers = !untranslate 
          ? ["Source Text", "Translation", "Missing Translation"]
          : ["Source", "Translation", "Untranslated Text"];
      
        const data = keyTermResult.map((item) => ({
          source: item?.Source, 
          translation: item?.Translation, 
          untranslated: item?.Untranslated_Text || item?.Missing_Term,
        }));
      

        const escapeCSVText = (text) => {
          if (text) {
            return `"${text.replace(/"/g, '""')}"`;
          }
          return '""'; 
        };
        const xlsRows = [];
        xlsRows.push(headers);  
      
        data.forEach((item) => {
          const row = [
            item.source,  
            item.translation, 
            item.untranslated,  
          ];
          xlsRows.push(row);
        });
      
       
        const ws = XLSX.utils.aoa_to_sheet(xlsRows);  
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "translations");
        XLSX.writeFile(wb, "translations.xlsx");
      };
      
  return (
    <div className="bg-white rounded-lg p-4">
      <div className=" w-full px-3 rounded-lg mb-4 flex items-center justify-between gap-5">
        <p className="text-base font-opensans font-semibold flex flex-col">
          <span>{headerText}</span>
          <span className="text-[#6E6E6E] font-opensans font-semibold text-[12px]">{headerDescription}</span>
        </p>
        <div className="flex gap-4 items-center justify-between  ">
          <button
            data-tooltip-id="copy-tooltip"
            data-tooltip-content={copySuccess}
            onClick={copyText}
            className=""
          >
            <img src={CopyIcon} alt="/copyicons" />
          </button>
          <DownloadSection
                handleDownload={handleDownload}
                selectedFormat={selectedFormat}
                handleFormatChange={handleFormatChange}
                downloadOutputFormat={downloadOutputFormat}
              />
        </div>
        <Tooltip
          id="copy-tooltip"
          place="top"
          multiline={true}
          className="z-50"
        />

      </div>
      <ul ref={refDiv}
    
        className=" w-full text-sm text-left rtl:text-right relative   shadow-md sm:rounded-lg"
      >
        <li className="flex">
          <p className="px-6 py-3 w-[33%] md:w-[25.5%] bg-[#BDD3FF] rounded-tl-lg border-none outline-none text-[#575757] font-opensans text-start font-semibold">           
            
            {untranslate ? "Source" : "Source Text"}
          </p>
          <p className="p-3  w-[33%] md:w-[25.5%]  text-start border-none outline-none  text-[#202020] text-base font-opensans  bg-[#E0EBFF] font-semibold   whitespace-wrap break-words">
          Translation
          </p>
          <p className="p-3 bg-[#BDD3FF] w-[34%] md:w-[49%] border-none outline-none rounded-tr-lg text-start text-[#202020] font-opensans text-base font-semibold  whitespace-wrap break-words">
          
          {untranslate ? "Untranslated Text" : "Missing Translation"}
          </p>
        </li>
        {keyTermResult.length > 5 ? (
          <div className="mt-2">
               <Slider {...settings} ref={sliderRef}>
            {keyTermResult?.map((item, index) => (
          <li className="!flex h-full lg:h-max my-2" key={index}>
            <p
              className="p-3 w-[33%] md:w-[25.5%] text-start border-none outline-none font-bold text-[#202020] text-base font-opensans bg-[#E0EBFF] whitespace-wrap break-words"
            >
              {item?.Source}
            </p>
            <p className="p-3 w-[33%] md:w-[25.5%] text-start border-none outline-none font-normal text-[#202020] text-base font-opensans bg-[#F0F5FF] whitespace-wrap break-words">
              {item?.Translation}
            </p>
            <p
              className="p-3 w-[34%] bg-[#E0EBFF] md:w-[49%] border-none outline-none text-start text-[#202020] font-opensans text-base font-normal whitespace-wrap break-words"
            >
              {item?.Untranslated_Text || item?.Missing_Term}
            </p>
          </li>
        ))}
      </Slider>
      <div className="h-16 mt-2  flex gap-2 items-center justify-center ">
                <div
        
                  className="absolute -bottom-[39px] w-[200px]  h-24  flex items-center justify-center gap-5 "
                >
                  <img
                  onClick={handlePrevSlide}
                    style={{ height: "60px", width: "60px" }}
                    src={Nexticon}
                    alt="Next"
                    className=" swiper-button-next    left-[-30px] -translate-y-1/2 cursor-pointer"
                  />
                  <p className="mb-12 text-base font-semibold font-opensans text-[#424242]">
                    {" "}
                    Page {currentPage} of {totalPages}{" "}
                  </p>
                  <img
                   onClick={handleNextSlide}
                    style={{ height: "60px", width: "60px" }}
                    src={Pre}
                    alt="Previous"
                    className=" h-[60px] w-[60px] swiper-button-prev   right-[-30px];  -translate-y-1/2 cursor-pointer"
                  />
                </div>
                </div>
         
          </div>
        ) : (
          <div className="mt-6 flex flex-col gap-2 ">
            {keyTermResult &&
              keyTermResult?.map((item, index) => (
                <li className={`flex h-full lg:h-max `} key={index}>
                  <p
                    className={`p-3 w-[33%] md:w-[25.5%] text-start border-none outline-none font-bold text-[#202020] text-base font-opensans bg-[#E0EBFF] whitespace-wrap break-words `}
                  >
                    {item?.Source}
                  </p>

                  <p className="p-3 w-[33%] md:w-[25.5%] text-start border-none outline-none font-normal text-[#202020] text-base font-opensans bg-[#F0F5FF] whitespace-wrap break-words">
                    {item?.Translation}
                  </p>
                  <p
                    className={`p-3 w-[34%] bg-[#E0EBFF] md:w-[49%] border-none outline-none text-start text-[#202020] font-opensans text-base font-normal whitespace-wrap break-words `}
                  >
                    {item?.Untranslated_Text || item?.Missing_Term}
                  </p>
                </li>
              ))}
          </div>
        )}
      </ul>
    </div>
  );
};

export default TermsOutput;
