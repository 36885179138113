import React, { useEffect, useRef, useState } from "react";
import "swiper/css";
import "swiper/css/grid";
import { Grid, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Nexticon from "assets/nexticon.svg";
import Pre from "assets/pre.svg";
import { Tooltip } from "react-tooltip";
import DownloadIcon from "assets/downloadtool.jpg";
import CopyIcon from "assets/copytools.jpg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import * as XLSX from "xlsx";
import DownloadSection from "utils/resultDownload";

const SourceQaOutput = ({sourceOutput}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedFormat, setSelectedFormat] = useState("Download CSV");
  const downloadOutputFormat = ["Download CSV", "Download XLS"];
      const sliderRef = useRef(null);
    const refDiv = useRef(null);
    const [copySuccess, setCopySuccess] = useState("Copy");
  const handleSlideChange = (swiper) => {
    const currentIndex = swiper.activeIndex + 1;
    setCurrentPage(currentIndex);
  };

  const keyTermResult = sourceOutput?.result?.QA_Analysis;

let sourceOutputArray = keyTermResult ? Object.entries(keyTermResult) : [];

const copyText = () => {
    if (refDiv.current) {
      const textToCopy = refDiv.current.innerText;
      navigator.clipboard.writeText(textToCopy);
      setCopySuccess("Copied");
      setTimeout(() => {
        setCopySuccess("Copy");
      }, 1500);
    }
  };
  const handleFormatChange = (format) => {
    setSelectedFormat(format);
  };

  const handleDownload = (format) => {
    if (format === "Download CSV") {
      downloadCSV();
    } else if (format === "Download XLS") {
      downloadXLS();
    }
  };
const downloadCSV = () => {
    const rows = [];

    rows.push([
      "QA Analysis",
      "Source",
      "Translation",
      "Issue",
      "Justification",
      "Correction",
    ]);

    sourceOutputArray.forEach(([sectionKey, sectionData]) => {
      sectionData.forEach((item) => {
        rows.push([
          sectionKey,
          item.Comparison?.Source || "",
          item.Comparison?.Translation || "",
          item.Issue || "",
          item.Justification || "",
          item.Correction || "",
        ]);
      });
    });

    const csvContent = "data:text/csv;charset=utf-8," 
      + rows.map(row => row.join(",")).join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "QA_Analysis.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const downloadXLS = () => {
    const rows = [];
  
    // Add headers for the XLSX sheet
    rows.push([
      "QA Analysis",
      "Source",
      "Translation",
      "Issue",
      "Justification",
      "Correction",
    ]);
  
    // Add data rows
    sourceOutputArray.forEach(([sectionKey, sectionData]) => {
      sectionData.forEach((item) => {
        rows.push([
          sectionKey,
          item.Comparison?.Source || "",
          item.Comparison?.Translation || "",
          item.Issue || "",
          item.Justification || "",
          item.Correction || "",
        ]);
      });
    });

    const ws = XLSX.utils.aoa_to_sheet(rows); 
    const wb = XLSX.utils.book_new(); 
    XLSX.utils.book_append_sheet(wb, ws, "Translations"); 

    XLSX.writeFile(wb, "translations.xlsx");
  };
  

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    rows: 5,
    beforeChange: (current, next) => {
      setCurrentPage(next + 1);
    },
    focusOnSelect: true,
  };
      const handleNextSlide = () => {
        sliderRef.current.slickPrev();
      };
    
      const handlePrevSlide = () => {
        sliderRef.current.slickNext();
      };
              useEffect(() => {
                setTotalPages(Math.ceil(sourceOutputArray.length / 5));
              }, [sourceOutputArray]);
  
  return (
    <div className="bg-white rounded-lg p-4">
    <div className=" w-full px-3 rounded-lg mb-4 flex items-center justify-between gap-5">
        <p className="text-base font-opensans  font-semibold flex flex-col">
          <span>Compare Source and Translation for a Quality Assessment</span>
          <span className="text-[#6E6E6E] font-opensans font-semibold text-[12px]">
          Analyzes your translation against the source text to assess accuracy, consistency, and proper localization based on several linguistic guidelines.
          </span>
        </p>
        <div className="flex gap-4 items-center justify-between  ">
          <button
            data-tooltip-id="copy-tooltip"
            data-tooltip-content={copySuccess}
            onClick={copyText}
            className=""
          >
            <img src={CopyIcon} alt="/copyicons" />
          </button>
          <DownloadSection
                handleDownload={handleDownload}
                selectedFormat={selectedFormat}
                handleFormatChange={handleFormatChange}
                downloadOutputFormat={downloadOutputFormat}
              />
        </div>
        <Tooltip
          id="copy-tooltip"
          place="top"
          multiline={true}
          className="z-50"
        />
       
      </div>
    <ul ref={refDiv}
      //  style={{ maxHeight: isLgScreen ? `${divHeight - 22}px` : "" }}
      className=" w-full text-sm text-left rtl:text-right relative    shadow-md sm:rounded-lg "
    >
      <li className="flex">
        {/* <p className="px-6 py-3 w-[20%] bg-[#BDD3FF] border-none outline-none text-[#575757] font-opensans text-start font-semibold">
        QA Analysis
        </p> */}
        <p className="p-3  w-[20%]  text-start border-none outline-none  text-[#202020] text-base font-opensans  bg-[#E0EBFF] font-semibold   whitespace-wrap break-words">
        Source
        </p>
        <p className="p-3 bg-[#BDD3FF] w-[20%] border-none outline-none text-start text-[#202020] font-opensans text-base font-semibold  whitespace-wrap break-words">
          Translation
        </p>
        <p className="p-3  w-[20%]  text-start border-none outline-none  text-[#202020] text-base font-opensans  bg-[#E0EBFF] font-semibold   whitespace-wrap break-words">
        Issue
        </p>
        <p className="p-3 bg-[#BDD3FF] w-[20%] border-none outline-none text-start text-[#202020] font-opensans text-base font-semibold  whitespace-wrap break-words">
        Justification
        </p>
        <p className="p-3  w-[20%]  text-start border-none outline-none  text-[#202020] text-base font-opensans  bg-[#E0EBFF] font-semibold   whitespace-wrap break-words">
        Correction
        </p>
      </li>
      {sourceOutputArray.length > 5 ? (
      <div className="mt-2">
   

          <Slider {...settings} ref={sliderRef}>
        {keyTermResult &&
          Object.keys(keyTermResult).map((sectionKey, index) => {
            const sectionData = keyTermResult[sectionKey];
       
              return (
                <div key={index} className="!flex !flex-col !h-full !gap-4 lg:!h-max !my-2 ">
                  {sectionData.map((item, idx) => (
                   
                    <li key={idx} className={`flex h-max  lg:h-max `}>
                      {/* <p
                        className={`p-3 w-[20%] text-start border-none outline-none font-bold text-[#202020] text-base font-opensans bg-[#E0EBFF] whitespace-wrap break-words`}
                      >
                        {sectionKey.replace(/_/g, " ")}

                      </p> */}
                      <p
                        className={`p-3  bg-[#F0F5FF] w-[20%] border-none outline-none text-start text-[#202020] font-opensans text-base font-normal whitespace-wrap break-words`}
                      >
                        {item.Comparison?.Source}
                      </p>
                      <p className="p-3 w-[20%] text-start border-none outline-none font-normal text-[#202020] text-base font-opensans bg-[#E0EBFF] whitespace-wrap break-words">
                        {item.Comparison?.Translation}
                      </p>
                      <p className="p-3 w-[20%] text-start border-none outline-none font-normal text-[#202020] text-base font-opensans bg-[#F0F5FF] whitespace-wrap break-words">
                        {item.Issue}
                      </p>
                   
                      <p
                        className={`p-3 w-[20%] border-none outline-none text-start text-[#202020] bg-[#E0EBFF] font-opensans text-base font-normal whitespace-wrap break-words`}
                      >
                        {item.Justification}
                      </p>
                      <p
                        className={`p-3  bg-[#F0F5FF] w-[20%] border-none outline-none text-start text-[#202020] font-opensans text-base font-normal whitespace-wrap break-words`}
                      >
                       {item?.Correction}
                      </p>
                    </li>
               
                  ))}
                </div>
              );
          
            
          })}
          </Slider>
          <div className="h-16 mt-4  flex gap-2 items-center justify-center ">
            <div
  
              className="absolute -bottom-[39px] w-[200px]  h-24  flex items-center justify-center gap-5 "
            >
              <img
                onClick={handlePrevSlide}
                style={{ height: "60px", width: "60px" }}
                src={Nexticon}
                alt="Next"
                className=" swiper-button-next    left-[-30px] -translate-y-1/2 cursor-pointer"
              />
              <p className="mb-12 text-base font-semibold font-opensans text-[#424242]">
                {" "}
                Page {currentPage} of {totalPages}{" "}
              </p>
              <img
                onClick={handleNextSlide}
                style={{ height: "60px", width: "60px" }}
                src={Pre}
                alt="Previous"
                className=" h-[60px] w-[60px] swiper-button-prev   right-[-30px];  -translate-y-1/2 cursor-pointer"
              />
            </div>
          </div>
         </div>
       
   
      ) : ( 
        <div className=" w-full text-sm text-left rtl:text-right relative  shadow-md sm:rounded-lg">
        {keyTermResult &&
          Object.keys(keyTermResult).map((sectionKey, index) => {
            const sectionData = keyTermResult[sectionKey];
       
              return (
                <div key={index} className="flex flex-col gap-2 mt-2">
                  {sectionData.map((item, idx) => (
                    <li key={idx} className={`flex h-full lg:!h-max `}>
                      {/* <p
                        className={`p-3 w-[20%] text-start border-none outline-none font-bold text-[#202020] text-base font-opensans bg-[#E0EBFF] whitespace-wrap break-words`}
                      >
                        {sectionKey.replace(/_/g, " ")}

                      </p> */}
                      <p
                        className={`p-3 bg-[#F0F5FF] w-[20%] border-none outline-none text-start text-[#202020] font-opensans text-base font-normal whitespace-wrap break-words`}
                      >
                        {item.Comparison?.Source}
                      </p>
                      <p className="p-3 w-[20%] text-start border-none outline-none font-normal text-[#202020] text-base font-opensans bg-[#E0EBFF] whitespace-wrap break-words">
                        {item.Comparison?.Translation}
                      </p>
                      <p className="p-3 w-[20%] text-start border-none outline-none font-normal text-[#202020] text-base font-opensans bg-[#F0F5FF] whitespace-wrap break-words">
                        {item.Issue}
                      </p>
                   
                      <p
                        className={`p-3 w-[20%] border-none outline-none text-start text-[#202020] bg-[#E0EBFF] font-opensans text-base font-normal whitespace-wrap break-words`}
                      >
                        {item.Justification}
                      </p>
                      <p
                        className={`p-3  bg-[#F0F5FF] w-[20%] border-none outline-none text-start text-[#202020] font-opensans text-base font-normal whitespace-wrap break-words`}
                      >
                          {item?.Correction}
                      </p>
                    </li>
                  ))}
                </div>
              );
          
            
          })}
      </div>
      )} 



    </ul>
    </div>
  );
};

export default SourceQaOutput;

